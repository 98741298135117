<template>
  <base_layout :key="params" @searchInput="searchInput">
    <transition name="opacity">
      <message @resetMessage="resetMessage" v-if="isMessage" :success="successMessage" :message="addMessage"/>
    </transition>
    <div class="content">
      <div class="customers">
        <div class="customers__instruments">
          <div class="customers__title-and-filters">
            <h1 style="display: flex; align-items: center" class="title-page">Kunden
              <span
                class="archive-span"
                v-if="params === 'archive'"
                style="margin-left: 16px;"
              >
                archiv
              </span>
            </h1>
          </div>
          <div v-if="params !== 'archive'" class="group-btn">
            <button @click.prevent="showPopup('isPopupCustomer')" class="blue-button"><span></span>Neuer
              Kunde
            </button>
          </div>
        </div>
        <div v-if="mainLoader" :class="{'fadein-animation': !mainLoader}" class="big-loader loader">
          <span></span></div>
        <div v-else :class="{'fadein-animation': !mainLoader}" ref="scrollContainer"
             @scroll="handleScroll('debounceSearchCustomers', 'debounceGetCustomers')"
             class="table">
          <table v-if="customers.length !== 0" cellspacing="0" cellpadding="0">
            <thead>
            <tr>
              <td class="text-transform-uppercase">Kunden-ID</td>
              <td class="text-transform-uppercase">Typ Kunde</td>
              <td class="text-transform-uppercase">Kunde</td>
              <td class="text-transform-uppercase">Mengenzähler</td>
              <td v-if="params !== 'archive'" class="text-transform-uppercase">Adresse</td>
              <td v-if="params !== 'archive'" class="text-transform-uppercase">Privates Telefon</td>
              <td v-if="params !== 'archive'" class="text-transform-uppercase">Persönliches Telefon</td>
              <td v-if="params === 'archive'" class="text-transform-uppercase">Grund für die Aufnahme in das Archiv</td>
              <td v-if="params === 'archive'" class="text-transform-uppercase">Kommentar</td>
              <td class="text-transform-uppercase">Aktion</td>
            </tr>
            </thead>
            <tbody>
            <tr :class="{'tr-checked': customer.isChecked}" v-for="(customer, index) in customers"
                :key="'customer_' + index">
              <td class="table__column-id">
                <a href="#" @click.prevent="onRowClick(customer.client.id)">#{{
                    customer.client.id
                  }}</a>
              </td>
              <td>{{ customer.client.type_client }}</td>
              <td>
                <a href="#" @click.prevent="onRowClick(customer.client.id)">
                  {{ customer.client.surname }} {{ customer.client.name }}
                </a>
              </td>
              <td>{{ customer.counters.length }}</td>
              <td v-if="params !== 'archive'" class="table__column-address"><span>{{
                  customer.client.accommodation_street
                }} {{ Number(customer.client.accommodation_house_number) ? Math.round(Number(customer.client.accommodation_house_number)) : customer.client.accommodation_house_number }}</span></td>
              <td v-if="params !== 'archive'">{{ customer.client.private_phone }}</td>
              <td v-if="params !== 'archive'">{{ customer.client.personal_phone }}</td>
              <td v-if="params === 'archive'">{{ customer.client.reason_for_client_leaving }}</td>
              <td v-if="params === 'archive'">{{ customer.client.comment }}</td>
              <td style="position: relative">
                <a @click.prevent="deleteWindow = index" style="position: relative; z-index: 1;" href="#">
                  <SidebarArchive width="16"/>
                </a>
                <div v-if="deleteWindow === index" class="warning-delete">
                  <span v-if="params !== 'archive'">Sind Sie sicher, dass Sie den Kunden in das Archiv verschieben wollen?</span>
                  <span v-else>Sind Sie sicher, dass Sie den Client aus dem Archiv entfernen wollen?</span>
                  <div>
                    <button @click.prevent="openPopupArchive(customer.client.id)">Ja</button>
                    <button @click.prevent="deleteWindow = null">Nein</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="warn-blank-table" v-else>Die Tabelle enthält keine Daten</div>
          <div v-if="isLoading" class="loader"><span></span></div>
        </div>
      </div>
    </div>
    <create-customer
      :customer="customer"
      :contract="contract"
      :counters="counters"
      :operators="operators"
      :is-popup-edit-customer="isPopupEditCustomer"
      :is-popup-customer-edit-counters="isPopupCustomerEditCounters"
      :is-popup-customer-edit-contracts="isPopupCustomerEditContracts"
      :is-popup-customer="isPopupCustomer"
      :is-popup-customer-counters="isPopupCustomerCounters"
      :is-popup-customer-contracts="isPopupCustomerContracts"
      @showCounters="showPopup('isPopupCustomerCounters')"
      @closePopup="closePopup"
      @showContracts="showPopup('isPopupCustomerContracts')"
      @addCounter="addCounter"
      @addContractInCounter="addContractInCounter"
      @createCustomer="createCustomer"
      @showEditCounter="editOpenCounter"
      @showEditContract="editOpenContract"
      @editCounter="editCounter"
      @editContractInCounter="editContract"
      @searchOperators="searchOperatorsInDropdown"
      @createAll="createAllCustomer"
      @closeAllPopup="closeAllPopup"
      :telegram-customer-id="telegramCustomerId"
      ref="createCustomer"
    />
    <ReasonForArchiving
      ref="reasonForArchiving"
      @sendArchive="updateCustomers"
      @archiveSuccess="showMessage(true, `Der Client wurde erfolgreich migriert`)"
      @archiveError="showMessage(false, `[ERROR] Migration des Clients fehlgeschlagen`)"
    />
  </base_layout>
</template>

<script>
import Base_layout from "@/layouts/base_layout";

// PLUGINS
import Vue from 'vue';
import {debounce} from "lodash";

// COMPONENTS
import BaseFieldInput from "@/components/fields/BaseFieldInput.vue";
import BaseCheckbox from "@/components/fields/BaseCheckbox.vue";
import message from "@/components/message";
import createCustomer from "@/components/popups/createCustomer.vue";

// MIXINS
import togglePopup from "@/mixins/components/togglePopup";
import success_error_state from "@/mixins/components/success_error_state";
import customerValidations from "@/mixins/customers/validations";
import lazyLoadScrollTable from '@/mixins/components/lazyLoadScrollTable'
import archive from "@/mixins/archive/archive";
import notification from "@/mixins/notification";

//DECORATORS
import {handle} from "@/decorator";
import SidebarArchive from "@/components/icons/SidebarArchive.vue";
import ReasonForArchiving from "@/components/popups/ReasonForArchiving.vue";

export default {
  name: "customers_page",
  components: {
    ReasonForArchiving,
    SidebarArchive,
    Base_layout,
    BaseFieldInput,
    BaseCheckbox,
    message,
    createCustomer
  },
  mixins: [
    togglePopup,
    success_error_state,
    customerValidations,
    lazyLoadScrollTable,
    archive,
    notification
  ],
  data() {
    return {
      telegramCustomerId: null,
      params: this.$route.params.type,
      deleteWindow: null,
      operators: [],
      customers: [],
      user: null,
      customer: {
        customer_id: '',
        type_client: '',
        addressing_customers: '',
        company: '',
        surname: '',
        name: '',
        accommodation_street: '',
        accommodation_house_number: '',
        accommodation_postcode: '',
        accommodation_city: '',
        private_phone: '',
        personal_phone: '',
        email: '',
        email_private_password: '',
        email_private: '',
        telegram_id: '',
        date_of_birth: '',
        recommender: '',
        counters: []
      },
      counters: {
        counter_id: '',
        counters_type: 'Type',
        counters_number: '',
        counter_street: '',
        counter_house_number: '',
        counter_postcode: '',
        counter_city: '',
        iban: '',
        bic: '',
        banks_name: '',
        account_owner: '',
        mao_id: ''
      },
      contract: {
        contract_id: '',
        counters_id: '',
        operator_name: '',
        customer_number: '',
        prior_year_consumption: '',
        contract_start_date: '',
        contract_end_date: '',
        start_count: '',
        end_count: '',
        start_count_night: '',
        end_count_night: '',
        condition_number: '',
        calorific_value: '',
        consumption: '',
        consumption_night: '',
        contract_status: 'Pending',
        cost_per_day: '',
        cost_per_night: '',
        cost_monthly: ''
      },
      countNextPage: 1,
      isLoading: false,
      searchInputs: '',
      countSearchNextPage: 1,
      mainLoader: true,
      showDeleteBtn: false
    }
  },
  methods: {
    async updateCustomers() {
      this.customers = await this.getCustomers()
    },
    async searchOperatorsInDropdown(event) {
      this.operators = []
      if (event.length) {
        let response = await this.$services.searchOperatorsInDropdown.callMethod({
          page: 1,
          search: event
        })
        let result = await response.json()
        for (const operator in result) {
          this.operators.push(result[operator].operator_name)
        }
      } else {
        await this.getOperatorsInDropdown()
      }
    },
    async getOperatorsInDropdown() {
      this.operators = []
      let response = await this.$services.getOperatorsInDropdown.callMethod()
      const result = await response.json()
      for (const oper in result) {
        this.operators.push(result[oper].operator_name)
      }
      return this.operators
    },
    editContract() {
      for (const contract_counter in this.counters.contracts[this.contract.index]) {
        for (const contract in this.contract) {
          if (this.counters.contracts[this.contract.index][contract_counter] !== this.contract[contract] && (contract_counter === contract || contract === `'doc_'${contract_counter}`)) {
            this.counters.contracts[this.contract.index][contract_counter] = this.contract[contract]
          }
        }
      }
      this.closePopup()
    },
    editCounter() {
      for (const customer in this.customer.counters[this.counters.index]) {
        for (const counter in this.counters) {
          if (this.customer.counters[this.counters.index][customer] !== this.counters[counter] && customer === counter) {
            this.customer.counters[this.counters.index][customer] = this.counters[counter]
          }
          this.customer.counters[this.counters.index]['contracts'] = this.counters["contracts"]
        }
      }
      this.closePopup()
    },
    addContractInCounter() {
      if (!this.counters.contracts) {
        this.counters.contracts = []
      }
      this.counters.contracts.push(this.contract)
      this.contract = {
        contract_id: '',
        counters_id: '',
        operator_name: '',
        customer_number: '',
        prior_year_consumption: '',
        contract_start_date: '',
        contract_end_date: '',
        start_count: '',
        end_count: '',
        start_count_night: '',
        end_count_night: '',
        condition_number: '',
        calorific_value: '',
        consumption: '',
        consumption_night: '',
        contract_status: 'Pending',
        cost_per_day: '',
        cost_per_night: '',
        cost_monthly: ''
      }
      this.closePopup()
    },
    addCounter() {
      if (this.$v.counters.$invalid) {
        this.$refs.createCustomer.callValidation('counters')
        return
      }
      this.counters.isNewEdit = true
      this.customer.counters.push(this.counters)
      this.counters = {
        counter_id: '',
        counters_type: 'Type',
        counters_number: '',
        counter_street: '',
        counter_house_number: '',
        counter_postcode: '',
        counter_city: '',
        iban: 'DE',
        bic: '',
        banks_name: '',
        account_owner: '',
        mao_id: ''
      }
      this.closePopup()

    },
    async searchInput() {
      this.search = true
      this.countNextPage = 1
      this.countSearchNextPage = 2
      this.checkHeight = false
      const $event = event.target.value
      this.searchInputs = $event
      if (!$event.length) {
        this.customers = []
        await this.getPaginationCustomers()
      } else {
        const obj = {
          search: $event,
          page: 1,
        }
        if (this.params === 'archive') {
          obj.archive = true
        }
        let response = await this.$services.searchCustomers.callMethod(obj)
        this.customers = await response.json()
      }
    },
    async searchPaginationInput() {
      try {
        const obj = {
          search: this.searchInputs,
          page: this.countSearchNextPage,
        }
        if (this.params === 'archive') {
          obj.archive = true
        }
        let response = await this.$services.searchCustomers.callMethod(obj)
        let result = await response.json()
        this.customers = [...this.customers, ...result]
        this.addIsCheckedBool()
        this.countSearchNextPage++
      } catch (e) {
        this.isLoading = false
        this.checkHeight = true
      }
    },
    async getPaginationCustomers() {
      this.search = false
      try {
        let result = await this.getCustomers(this.countNextPage)
        this.customers = [...this.customers, ...result]
        this.addIsCheckedBool()
        this.countNextPage++
      } catch (e) {
        this.isLoading = false
        this.checkHeight = true
      }
    },
    async getCustomers(count = 1) {
      const obj = {page: count}
      if (this.params === 'archive') {
        obj.archive = true
      }
      let response = await this.$services.getCustomers.callMethod(obj)
      let result = await response.json()
      return result
    },
    addIsCheckedBool() {
      this.customers.forEach((customers) => {
        Vue.set(customers, 'isChecked', false)
      })
    },
    async createAllCustomer() {
      if (this.isPopupCustomerContracts) {
        if (!this.counters.contracts) {
          this.counters.contracts = []
        }
        this.counters.contracts.push(this.contract)
        this.contract = {
          contract_id: '',
          counters_id: '',
          operator_name: '',
          customer_number: '',
          prior_year_consumption: '',
          contract_start_date: '',
          contract_end_date: '',
          start_count: '',
          end_count: '',
          start_count_night: '',
          end_count_night: '',
          condition_number: '',
          calorific_value: '',
          consumption: '',
          consumption_night: '',
          contract_status: 'Pending',
          cost_per_day: '',
          cost_per_night: '',
          cost_monthly: ''
        }

        if (this.$v.counters.$invalid) {
          this.isPopupCustomerContracts = false
          this.isPopupCustomerEditContracts = false
          this.$refs.createCustomer.callValidation('counters')
          return
        }

        if (!this.isPopupCustomerEditCounters) {
          this.customer.counters.push(this.counters)
        } else {
          this.editCounter()
        }


        if (this.$v.customer.$invalid) {
          this.isPopupCustomerContracts = false
          this.isPopupCustomerEditContracts = false
          this.isPopupCustomerCounters = false
          this.isPopupCustomerEditCounters = false
          this.$refs.createCustomer.callValidation('customer')
          return
        }

        this.isPopupCustomerContracts = false
        this.isPopupCustomerEditContracts = false
        this.isPopupCustomerCounters = false
        this.isPopupCustomerEditCounters = false
      } else if (this.isPopupCustomerCounters) {

        if (this.$v.counters.$invalid) {
          this.$refs.createCustomer.callValidation('counters')
          return
        }

        if (this.$v.customer.$invalid) {
          this.isPopupCustomerCounters = false
          this.isPopupCustomerEditCounters = false
          this.$refs.createCustomer.callValidation('customer')
          return
        }
        this.counters.isNewEdit = true
        if (!this.isPopupCustomerEditCounters) {
          this.customer.counters.push(this.counters)
        } else {
          this.editCounter()
        }
        this.counters = {
          counter_id: '',
          counters_type: 'Type',
          counters_number: '',
          counter_street: '',
          counter_house_number: '',
          counter_postcode: '',
          counter_city: '',
          iban: 'DE',
          bic: '',
          banks_name: '',
          account_owner: '',
          mao_id: ''
        }


        this.isPopupCustomerCounters = false
        this.isPopupCustomerEditCounters = false
      } else if (this.isPopupCustomer) {
        if (this.$v.customer.$invalid) {
          this.$refs.createCustomer.callValidation('customer')
          return
        }
      }
      this.isPopupCustomer = false
      await this.createCustomer()
    },
    closeAllPopup() {
      this.isPopupCustomerContracts = false
      this.isPopupCustomerEditContracts = false
      this.isPopupCustomerCounters = false
      this.isPopupCustomerEditCounters = false
      this.isPopupCustomer = false
      this.isPopupEditCustomer = false
    },
    async createCustomer() {
      if (this.$v.customer.$invalid) {
        this.$refs.createCustomer.callValidation('customer')
        return;
      } else {
        const formData = new FormData()
        formData.append('customer', JSON.stringify(this.customer))
        try {
          let response = await this.$services.createCustomer.callMethod(formData)
          let result = await response.json()
          this.telegramCustomerId = result.customer_id
          console.log(this.telegramCustomerId)
          if (result.status === 'error') {
            this.showMessage(false, '[Error] Anlegen eines Kunden fehlgeschlagen')
          } else {
            this.$refs.createCustomer.$data.isNewUser = true
            this.showMessage(true, 'Der Kunde wurde erfolgreich angelegt')
            this.customers = await this.getCustomers()
          }
        } catch (e) {
          this.showMessage(false, '[Error] Anlegen eines Kunden fehlgeschlagen')
        } finally {
          this.customer = {
            customer_id: '',
            type_client: '',
            addressing_customers: '',
            company: '',
            surname: '',
            name: '',
            accommodation_street: '',
            accommodation_house_number: '',
            accommodation_postcode: '',
            accommodation_city: '',
            private_phone: '',
            personal_phone: '',
            email: '',
            email_private_password: '',
            email_private: '',
            telegram_id: '',
            date_of_birth: '',
            recommender: '',
            counters: []
          }
        }
        this.isLoading = false
        this.countSearchNextPage = 2
        this.checkHeight = false
        this.search = false
        this.closePopup()
      }
    },
    onRowClick(id) {
      this.$router.push({
        name: 'customer_page',
        params: {
          id: `${this.params}_${id}`
        }
      })
    },
  },
  async mounted() {
    await this.getPaginationCustomers()
    this.mainLoader = false
  },
  created() {
    this.debounceGetCustomers = debounce(this.getPaginationCustomers, 200)
    this.debounceSearchCustomers = debounce(this.searchPaginationInput, 200)
  },
  watch: {
    async '$route.params.type'(to) {
      this.params = to
      this.countNextPage = 1
      this.customers = await this.getCustomers()
      this.deleteWindow = null
    }
  }
}
</script>

<style lang="stylus">
@import "@/assets/stylus/popup.styl"
@import "@/assets/stylus/popup-customer.styl"

.customers
  height: 81vh

  .table
    max-height calc(100% - 90px)
    margin-top: 58px

  &__instruments
    display flex
    justify-content space-between
    align-items center

  &__title-and-filters
    display flex
    align-items center

    .filters
      margin-left: 32px

</style>
